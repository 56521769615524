// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-count-down_FnG6t{font-weight:400;color:var(--TextDefault)}.pin-count-down_FnG6t,.pin-count-down__timer_mD3Re{font-size:14px;line-height:24px;letter-spacing:.25px}.pin-count-down__timer_mD3Re{font-weight:500;color:var(--ErrorText)}.pin-count-down__time_RvFlc{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-count-down": `pin-count-down_FnG6t`,
	"pin-count-down__timer": `pin-count-down__timer_mD3Re`,
	"pin-count-down__time": `pin-count-down__time_RvFlc`
};
export default ___CSS_LOADER_EXPORT___;
