// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .pin-code-controller_h_5_q{padding:16px 32px 32px;touch-action:pan-x pan-y}html.app__layout--desktop .pin-code-controller--centered_FGbeh{display:flex;flex-grow:1;align-items:center;display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-code-controller": `pin-code-controller_h_5_q`,
	"pin-code-controller--centered": `pin-code-controller--centered_FGbeh`
};
export default ___CSS_LOADER_EXPORT___;
