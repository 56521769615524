// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .pin-code-route-component_Lkyqf{display:flex;flex-direction:column;row-gap:20px}html.app__layout--desktop .pin-code-route-component__forgot_f5a1V{display:inline-flex;align-items:center;justify-content:center;width:100%;height:44px}html.app__layout--desktop .pin-code-route-component__forgot-link_fN1Cw{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextDefault);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;background-color:#0000;border:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-code-route-component": `pin-code-route-component_Lkyqf`,
	"pin-code-route-component__forgot": `pin-code-route-component__forgot_f5a1V`,
	"pin-code-route-component__forgot-link": `pin-code-route-component__forgot-link_fN1Cw`
};
export default ___CSS_LOADER_EXPORT___;
